import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Skeleton } from "primereact/skeleton";
import { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import httpService from "../../../services/http.service";
import {
  createColumns,
  getErrorMessage,
  getToastMessage,
  loadingData,
} from "../../Helper/Helper";
import "./RequirementFile.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { over } from "stompjs";
import {
  faArrowUpRightFromSquare,
  faCheck,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { Button } from "primereact/button";
import { connectSocket } from "../../Helper/SocketHelper";
import { handleToastErrorCatch } from "../../Helper/ToastHelper";
import NoData from "../../UI/NoData/NoData";

const AttachedTestcases = (props) => {
  const ref_id = useRef(props.id);
  const [attachedTC, setAttachedTC] = useState(null);
  const [availableTC, setAvailableTC] = useState(null);
  const [dialog, setDialog] = useState(false);
  const [selectedTC, setSelectedTC] = useState(null);
  // const tempHeaders = useRef([" Id", " Name", "Description"])
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [
    isAttachNewTestcaseButtonLoading,
    setIsAttachNewTestcaseButtonLoading,
  ] = useState(false);
  const toDeleteTCName = useRef(null);
  const toDeleteTCId = useRef(null);
  const company = useSelector((state) => state.auth.userProfile.socket);
  const firstUpdate = useRef(false);
  const pidArray = useRef(null);
  const projectData = useSelector(
    (state) => state.requirements.project_data_requirements
  );
  const role = useSelector((state) => state.auth.userProfile.role);
  let stompClient = null;
  const tcConnection = useRef(null);

  const getAttachedTC = () => {
    httpService
      .getAttachedTC(ref_id.current)
      .then((data) => {
        setAttachedTC(data["data"]);
        if (!firstUpdate.current) {
          firstUpdate.current = true;
          connectSocket(
            "testpad",
            company.toString().toUpperCase() + "_REQUIREMENT/private",
            tcConnection,
            notifyTcClient
          );
          // tcConnect();
        }
      })
      .catch((err) => {
        if (
          err["response"]["data"]["operation"].toLowerCase() === "info" &&
          err["response"]["data"]["suboperation"]
        ) {
          props.setNoAccess(true);
          tcConnection?.current?.unsubscribe();
          return;
        }
        handleToastErrorCatch(err);
      });
  };
  useEffect(() => {
    getAttachedTC();
    return () => {
      tcConnection?.current?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (projectData.length > 0) {
      let pids = projectData.map((data) => data["pid"]);
      pidArray.current = pids;
    }
  }, [projectData]);

  const notifyTcClient = (e) => {
    if (e["body"]) {
      let wsBody = JSON.parse(e["body"]);
      if (pidArray.current && pidArray.current.includes(wsBody["pid"])) {
        getAttachedTC();
      }
    }
  };

  const getProjectTestcases = (e) => {
    setIsAttachNewTestcaseButtonLoading(true);
    httpService
      .getProjectTestcases(ref_id.current)
      .then((data) => {
        getErrorMessage(data);
        setAvailableTC(data["data"]);
        if (
          data["operation"] &&
          data["operation"].toLowerCase() === "success"
        ) {
          setDialog(true);
        }
        setIsAttachNewTestcaseButtonLoading(false);
      })
      .catch((err) => {
        handleToastErrorCatch(err, () =>
          setIsAttachNewTestcaseButtonLoading(false)
        );
      });
  };

  const addRemoveTC = (payload, type) => {
    setIsAttachNewTestcaseButtonLoading(true);
    httpService
      .addRemoveAttachedTC(payload, type)
      .then((data) => {
        getToastMessage(data);
        const action =
          type === "add"
            ? (setDialog(false), setAvailableTC(null), setSelectedTC(null))
            : (setDeleteLoading(false),
              setDeleteDialog(false),
              (toDeleteTCId.current = null),
              (toDeleteTCName.current = null));
        setIsAttachNewTestcaseButtonLoading(false);
        // getAttachedTC();
      })
      .catch((err) => {
        handleToastErrorCatch(err, () =>
          setIsAttachNewTestcaseButtonLoading(false)
        );
      });
  };

  const addNewTC = (e) => {
    let payload = {
      id: ref_id.current,
    };
    const ids = selectedTC
      ? Object.keys(selectedTC).map((data) => {
          return selectedTC[data]["Testcase Id"]["value"];
        })
      : null;

    payload["ids"] = ids;
    addRemoveTC(payload, "add");
  };

  const unlinkTC = (e, id) => {
    setDeleteLoading(true);
    let payload = {
      id: ref_id.current,
    };

    let ids = [];
    ids.push(id);

    payload["ids"] = ids;
    addRemoveTC(payload, "remove");
  };

  const getSkeleton = () => {
    const skelCards = Array.from(Array(9).keys()).map((card) => {
      return (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12 align-items-center my-2">
          <Skeleton
            width="23rem"
            height="7.5rem"
            className="card propertiesCardSkel"
          ></Skeleton>
        </div>
      );
    });
    return skelCards;
  };

  const createAttachmentCards = (data) => {
    const cards =
      data.length > 0 ? (
        data.map((attachmentData) => {
          return (
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 align-items-center my-2">
              {/* {attachmentData["Testcase Name"]["value"]} */}
              <div className="card propertiesCard pb-4 px-4 pt-3">
                <div className="d-flex">
                  <span className="ms-auto">
                    <FontAwesomeIcon
                      className="deleteReq me-3"
                      icon={faArrowUpRightFromSquare}
                      onClick={(e) => {
                        window.open(
                          window.location.origin +
                            "/#/testpad/MLAB?mtc_id=" +
                            attachmentData["Testcase Id"]["value"],
                          "_blank"
                        );
                      }}
                      title="Show Attachment"
                    />
                    <FontAwesomeIcon
                      className="deleteReq"
                      icon={faTrashCan}
                      onClick={(e) => (
                        (toDeleteTCId.current =
                          attachmentData["Testcase Id"]["value"]),
                        (toDeleteTCName.current =
                          attachmentData["Testcase Name"]["value"]),
                        setDeleteDialog(true)
                      )}
                      title="Unlink Attachment"
                    />
                    {/* <FontAwesomeIcon
                                    className="ms-2"
                                    icon={faArrowUpRightFromSquare}
                                    title="Show More"
                                /> */}
                  </span>
                </div>
                <div className="my-2">
                  <span className="boldText">Testcase ID : </span>
                  <span className="attach-cardData">
                    {attachmentData["Testcase Id"]["value"]}
                  </span>
                </div>
                <div className="my-2">
                  <span className="boldText">Testcase Name : </span>
                  <span className="attach-cardData">
                    {attachmentData["Testcase Name"]["value"]}
                  </span>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="mt-3 px-5 text-center">
          <div className="card d-flex align-items-center py-3">
            <div className="boldText">
              No Testcases Found ! Click on Attach New Testcases to add.{" "}
            </div>
          </div>
        </div>
      );

    return cards;
  };

  return (
    <div>
      <div className="d-flex">
        {role.toString().toUpperCase() !== "SUPER_ADMIN" && (
          <Button
            label="Attach New Testcase"
            className="btn-success btn themeBtn ms-auto p-2"
            onClick={() => getProjectTestcases()}
            loadingIcon="pi pi-spin pi-spinner me-2"
            iconPos="right"
            loading={isAttachNewTestcaseButtonLoading}
          />
        )}
      </div>
      {attachedTC ? (
        <div className="row d-flex mt-2">
          {attachedTC["data"]
            ? createAttachmentCards(attachedTC["data"])
            : null}
        </div>
      ) : (
        <div className="row d-flex">{getSkeleton()}</div>
      )}
      <Dialog
        blockScroll={true}
        header={`Attach New Testcase`}
        visible={dialog}
        onHide={() => {
          setDialog(false);
          setAvailableTC(null);
          setSelectedTC(null);
        }}
        draggable={false}
        breakpoints={{ "960px": "75vw" }}
        style={{
          width: "60vw",
        }}
        footer={
          <Button
            label="Attach"
            className="btn-success btn themeBtn ms-auto p-2"
            onClick={(e) => addNewTC(e)}
            loadingIcon="pi pi-spin pi-spinner me-2"
            iconPos="right"
            loading={isAttachNewTestcaseButtonLoading}
          />
        }
      >
        <DataTable
          reorderableColumns
          collapsedRowIcon="pi pi-arrow-circle-right"
          expandedRowIcon="pi pi-arrow-circle-down"
          selection={selectedTC}
          onSelectionChange={(e) => setSelectedTC(e.value)}
          removableSort
          // rowExpansionTemplate={(mainData) => (
          //     <>
          //         <div className="mt-4 text-center overHeadAnalytics">
          //             <h4>Testcase Details</h4>
          //         </div>
          //         <div className="d-flex justify-content-center ">
          //             <div className="w-50">
          //                 <table className=" shadow-sm mt-3 mb-3 table table-borderless testcaseTable">
          //                     <tbody>
          //                         {createExpandedRowData(mainData["frameworkData"])}{" "}
          //                     </tbody>
          //                 </table>
          //             </div>
          //         </div>
          //     </>
          // )}
          // expandedRows={testexpandedRows}
          // onRowToggle={(e, v) => {
          //     // setExpandedRows([e.data[e.data.length - 1]]); //to Toggle the rows if another row is expanded
          //     setTestExpandedRows(e.data); //to expand row without collaping other
          // }}
          // alwaysShowPaginator={false}
          resizableColumns
          stripedRows
          columnResizeMode="expand"
          value={
            availableTC && availableTC["data"] ? availableTC["data"] : null
          }
          dragSelection
          paginator
          rows={25}
          filterDisplay="menu"
          responsiveLayout="scroll"
          emptyMessage={<NoData />}
          currentPageReportTemplate="Total {totalRecords} Records Found"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown "
          rowsPerPageOptions={[5, 10, 25, 50, 80, 100]}
          scrollHeight={"55vh"}
          className="test-stepTable"
        >
          {/* <Column
                        // header="Step Report"
                        headerClassName={`headercolumnsPrime`}
                        className={"columnsPrime"}
                        expander={true}
                    // style={{ width: "10em" }}
                    /> */}
          <Column
            selectionMode="multiple"
            headerClassName={`headercolumnsPrime`}
            className={"columnsPrime"}
            headerStyle={{ width: "3em" }}
          ></Column>

          {availableTC && availableTC["data"] ? (
            createColumns(
              availableTC["headers"],
              true,
              false,
              availableTC["data"]
            )
          ) : (
            <></>
          )}
        </DataTable>
      </Dialog>
      <Dialog
        blockScroll={true}
        draggable={false}
        header="Unlink Testcase"
        visible={deleteDialog}
        onHide={() => {
          setDeleteDialog(false);
          setDeleteLoading(false);
          toDeleteTCId.current = null;
          toDeleteTCName.current = null;
        }}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "50vw" }}
      >
        {deleteLoading ? (
          <div className="py-5">
            {loadingData("Unlinking " + toDeleteTCName.current)}
          </div>
        ) : (
          <>
            <div className="text-center mt-3 py-2 mx-3">
              <h5>
                Are you sure you want to unlink : {toDeleteTCName.current}
              </h5>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <div
                onClick={(e) => {
                  unlinkTC(e, toDeleteTCId.current);
                }}
                className="btn btn-success"
              >
                <FontAwesomeIcon className="ms-1 tabIcon me-1" icon={faCheck} />
                Yes
              </div>
              <div
                onClick={() => {
                  setDeleteDialog(false);
                }}
                className="btn btn-danger mx-2"
              >
                <FontAwesomeIcon className="ms-1 tabIcon me-1" icon={faXmark} />
                No
              </div>
            </div>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default AttachedTestcases;
