import { useState, useEffect, useRef } from "react";
import {
  createColumns,
  createFilters,
  createGlobalFilters,
  getErrorMessage,
  getToastMessage,
  loadingData,
} from "../../Helper/Helper";
import { DataTable } from "primereact/datatable";
import "./ManualTCFile.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faLink,
  faPencil,
  faPlus,
  faTrashCan,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import httpService from "../../../services/http.service";
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { Column } from "primereact/column";
import { Skeleton } from "primereact/skeleton";
import { over } from "stompjs";
import { useSelector } from "react-redux";
import { Button } from "primereact/button";
import { connectSocket } from "../../Helper/SocketHelper";
import NoData from "../../UI/NoData/NoData";
import { handleToastErrorCatch } from "../../Helper/ToastHelper";

const TestStepsDraft = (props) => {
  const [testStepsData, setTestStepsData] = useState(null);
  const [newStepDialog, setNewStepDialog] = useState(null);
  const [editStepDialog, setEditStepDialog] = useState(null);
  const mtc_id = useRef(props.id);
  const [stepName, setStepName] = useState("");
  const [description, setDescription] = useState("");
  const toEditStepId = useRef(null);
  const [expectedResult, setExpectedResult] = useState("");
  const [screenshotRequired, setScreenshotRequired] = useState("No");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const toDeleteStepid = useRef(null);
  const toDeleteStepname = useRef(null);
  const [allTC, setAllTC] = useState(null);
  const [selectedTC, setSelectedTC] = useState(null);
  const [tcDialog, setTcDialog] = useState(false);
  const [draftFilters, setDraftFilters] = useState({});
  const [savingDialog, setSavingDialog] = useState(false);
  const company = useSelector((state) => state.auth.userProfile.socket);
  const [importManualFilters, setImportManualFilters] = useState({});
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const firstUpdate = useRef(false);
  const pidArray = useRef(null);
  const projectData = useSelector(
    (state) => state.manualTC.project_data_manualtc
  );
  const [isAddStepButtonLoading, setIsAddStepButtonLoading] = useState(false);
  const [isImportTestcaseButtonLoading, setIsImportTestcaseButtonLoading] =
    useState(false);
  const [isCopyTestcaseButtonLoading, setIsCopyTestcaseButtonLoading] =
    useState(false);
  let stompClient = null;
  const tcConnection = useRef(null);

  useEffect(() => {
    getSteps();
    return () => {
      tcConnection?.current?.unsubscribe();
    };
  }, []);
  useEffect(() => {
    if (projectData.length > 0) {
      let pids = projectData.map((data) => data["pid"]);
      pidArray.current = pids;
    }
  }, [projectData]);

  useEffect(() => {
    if (Object.keys(importManualFilters).length === 0) {
      const filterData = allTC ? createGlobalFilters() : {};
      setImportManualFilters(filterData);
    }
  }, [allTC]);

  const notifyTcClient = (e) => {
    if (e["body"]) {
      let wsBody = JSON.parse(e["body"]);
      if (
        pidArray.current &&
        pidArray.current.includes(wsBody["pid"]) &&
        wsBody["message"]?.toLowerCase() === "draft"
      ) {
        getSteps();
      }
    }
  };

  useEffect(() => {
    if (Object.keys(draftFilters).length === 0) {
      const filterDraftData =
        testStepsData && testStepsData["headers"]
          ? createFilters(testStepsData["headers"])
          : {};

      setDraftFilters(filterDraftData);
    }
  }, [testStepsData]);

  const skeletonBridgeRows = Array.from({ length: 5 });
  const skeletonBridgeTemplate = () => {
    return <Skeleton></Skeleton>;
  };
  const tableSkeleton = (headerState) => {
    return (
      <DataTable
        responsiveLayout="scroll"
        value={skeletonBridgeRows}
        header={
          headerState ? (
            <>
              <div className="headingFont">
                <Skeleton width="15rem" className="my-3 ms-2"></Skeleton>
              </div>
            </>
          ) : null
        }
        className="p-datatable-striped mt-3 test-stepTable"
      >
        <Column
          header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
          style={{ width: "20%" }}
          body={skeletonBridgeTemplate}
        ></Column>
        <Column
          header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
          style={{ width: "15%" }}
          body={skeletonBridgeTemplate}
        ></Column>
        <Column
          header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
          style={{ width: "13%" }}
          body={skeletonBridgeTemplate}
        ></Column>
        <Column
          header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
          style={{ width: "13%" }}
          body={skeletonBridgeTemplate}
        ></Column>
        <Column
          header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
          style={{ width: "20%" }}
          body={skeletonBridgeTemplate}
        ></Column>
        <Column
          header={<Skeleton width="5rem" className="mx-auto"></Skeleton>}
          style={{ width: "18%" }}
          body={skeletonBridgeTemplate}
        ></Column>
      </DataTable>
    );
  };

  const getSteps = () => {
    httpService
      .getDraftedSteps(mtc_id.current)
      .then((data) => {
        getErrorMessage(data);
        setTestStepsData(data["data"]);
        if (!firstUpdate.current) {
          firstUpdate.current = true;
          connectSocket(
            "execution",
            company.toString().toUpperCase() + "_MANUAL/private",
            tcConnection,
            notifyTcClient
          );
        }
      })
      .catch((err) => {
        if (
          err["response"]["data"]["operation"].toLowerCase() === "info" &&
          err["response"]["data"]["suboperation"]
        ) {
          props.setNoAccess(true);
          tcConnection?.current?.unsubscribe();
          return;
        }
        toast.error(
          err["response"]
            ? err["response"]["data"]["message"]
            : `Something Went Wrong. 
    Please refresh the Page / Try Again`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "mt-5",
          }
        );
      });
  };

  const addEditStep = async (e, type) => {
    e?.preventDefault();

    if (
      stepName.length > 0 &&
      description.length > 0 &&
      expectedResult.length > 0 &&
      screenshotRequired.length > 0
    ) {
      let step = {
        id: type === "edit" ? toEditStepId.current : null,
        name: stepName,
        description: description,
        expectedResult: expectedResult,
        screenshot: screenshotRequired.toLowerCase() === "yes" ? true : false,
      };
      setIsAddStepButtonLoading(true);
      await httpService
        .addTestStep(step, mtc_id.current)
        .then(async (data) => {
          getToastMessage(data);
          if (
            data["operation"] &&
            data["operation"].toLowerCase() === "success"
          ) {
            setNewStepDialog(false);
            setEditStepDialog(false);
            setDescription("");
            setStepName("");
            setExpectedResult("");
            setScreenshotRequired("No");
            toEditStepId.current = null;
          }
        })
        .catch((err) => {
          toast.error(
            err["response"]
              ? err["response"]["data"]["message"]
              : `Something Went Wrong. 
    Please refresh the Page / Try Again`,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              className: "mt-5",
            }
          );
        });
      setIsAddStepButtonLoading(false);
    } else {
      toast.error(`All Fields are required!`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        className: "mt-5",
      });
    }
  };

  const copyTCAsSteps = async (e) => {
    e?.preventDefault();

    setIsCopyTestcaseButtonLoading(true);
    await httpService
      .copyTCAsStep(props.id, selectedTC["Testcase Id"]["value"])
      .then(async (data) => {
        getToastMessage(data);
        setTcDialog(false);
        setSelectedTC(null);
        setAllTC(null);
        setGlobalFilterValue("");
        setImportManualFilters({});
      })
      .catch((err) => {
        toast.error(
          err["response"]
            ? err["response"]["data"]["message"]
            : `Something Went Wrong. 
Please refresh the Page / Try Again`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "mt-5",
          }
        );
      });
    setIsCopyTestcaseButtonLoading(false);
  };

  const linkStepToTC = async (e) => {
    e?.preventDefault();
    setIsImportTestcaseButtonLoading(true);
    await httpService
      .linkTCAsStep(props.id, selectedTC["Testcase Id"]["value"])
      .then(async (data) => {
        getToastMessage(data);
        setTcDialog(false);
        setSelectedTC(null);
        setAllTC(null);
        setGlobalFilterValue("");
        setImportManualFilters({});
      })
      .catch((err) => {
        toast.error(
          err["response"]
            ? err["response"]["data"]["message"]
            : `Something Went Wrong. 
Please refresh the Page / Try Again`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "mt-5",
          }
        );
      });
    setIsImportTestcaseButtonLoading(false);
  };

  const stepForm = (type) => {
    return (
      <div className="p-2">
        <form>
          <div>
            <div className="row my-2">
              <div className="col-12">
                <div className="ms-1 mb-1 mt-1 pDetails-heading">
                  Step Name<sup> *</sup>
                </div>
                <div className="manualFormInputs">
                  <InputText
                    type={"text"}
                    value={stepName}
                    onChange={(e) => setStepName(e.target.value)}
                    className="w-100"
                    placeholder={"Step Name"}
                    disabled={isAddStepButtonLoading}
                  />
                </div>
              </div>
            </div>
            <div className="row my-2">
              <div className="col-md-6 col-12">
                <div className="ms-1 mb-1 mt-1 pDetails-heading">
                  Description<sup> *</sup>
                </div>
                <div className="">
                  <InputTextarea
                    value={description}
                    id="desc"
                    className="w-100 manualFormInputs"
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    disabled={isAddStepButtonLoading}
                    rows={8}
                    autoResize
                  />
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="ms-1 mb-1 mt-1 pDetails-heading">
                  Expected Result<sup> *</sup>
                </div>
                <div className="">
                  <InputTextarea
                    value={expectedResult}
                    id="desc"
                    className="w-100 manualFormInputs"
                    onChange={(e) => {
                      setExpectedResult(e.target.value);
                    }}
                    rows={8}
                    disabled={isAddStepButtonLoading}
                    autoResize
                  />
                </div>
              </div>
            </div>
            <div className="row my-2">
              <div className="col-12">
                <div className="ms-1 mb-1 mt-1 pDetails-heading">
                  Screenshot Required<sup> *</sup>
                </div>
                <div className="d-flex mt-2">
                  <div className="mx-2">
                    <RadioButton
                      inputId="yes"
                      name="screenshot"
                      value="Yes"
                      onChange={(e) => setScreenshotRequired(e.value)}
                      checked={screenshotRequired === "Yes"}
                      disabled={isAddStepButtonLoading}
                    />
                    <label htmlFor="yes" className="ms-2">
                      Yes
                    </label>
                  </div>
                  <div className="mx-2">
                    <RadioButton
                      inputId="no"
                      name="screenshot"
                      value="No"
                      onChange={(e) => setScreenshotRequired(e.value)}
                      checked={screenshotRequired === "No"}
                      disabled={isAddStepButtonLoading}
                    />
                    <label htmlFor="no" className="ms-2">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center">
              <Button
                label={type === "edit" ? "Save Step" : "Add Step"}
                type="submit"
                className="btn-success btn py-2 themeBtn"
                onClick={(e) => addEditStep(e, type)}
                loadingIcon={"pi pi-spin pi-spinner me-2"}
                loading={isAddStepButtonLoading}
                iconPos="right"
              />
            </div>
          </div>
        </form>
      </div>
    );
  };

  const deleteStep = (e) => {
    setDeleteLoading(true);

    httpService
      .deleteTestStep(mtc_id.current, toDeleteStepid.current)
      .then(async (data) => {
        getToastMessage(data);
        setDeleteLoading(false);
        setDeleteDialog(false);
      })
      .catch((err) => {
        toast.error(
          err["response"]
            ? err["response"]["data"]["message"]
            : `Something Went Wrong. 
    Please refresh the Page / Try Again`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "mt-5",
          }
        );
      });
  };

  const removeStepFromTC = (val, rowData) => {
    return (
      <div>
        <div className="d-flex text-center">
          <FontAwesomeIcon
            icon={faTrashCan}
            className="actionBtn"
            size="lg"
            onClick={(e) => {
              toDeleteStepid.current = val;
              toDeleteStepname.current = rowData["Name"]["value"];
              setDeleteDialog(true);
            }}
            title="Remove Step"
          />
        </div>
      </div>
    );
  };

  const onRowEditComplete = (e) => {
    // let _temp = [...testStepsData["data"]];
    // let _headers = [...testStepsData["headers"]];
    // let { newData, index } = e;
    // _temp[index] = newData;
    // let tempData = {
    //     data : _temp,
    //     headers : _headers
    // }
    // setTestStepsData(tempData);
  };
  const stepScreenshot = (val) => {
    return (
      <div className={`${val.props.val ? "pass-color" : "fail-color"}`}>
        {val.props.val ? "Required" : "Not Required"}
      </div>
    );
  };

  const editStepAction = (e, val, id) => {
    httpService
      .getEditStepData(id, val.props.val)
      .then(async (data) => {
        getErrorMessage(data);
        if (
          data["operation"] &&
          data["operation"].toLowerCase() === "success"
        ) {
          toEditStepId.current = val.props.val;
          setStepName(data["data"]["name"]);
          setDescription(data["data"]["description"]);
          setExpectedResult(data["data"]["expectedResult"]);
          setScreenshotRequired(data["data"]["screenshot"] ? "Yes" : "No");
          setEditStepDialog(true);
        }
      })
      .catch((err) => {
        toast.error(
          err["response"]
            ? err["response"]["data"]["message"]
            : `Something Went Wrong. 
Please refresh the Page / Try Again`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "mt-5",
          }
        );
      });
  };
  const editStep = (val) => {
    return (
      <div>
        {!val.props.val ? (
          <FontAwesomeIcon
            icon={faPencil}
            className="disabledIcon"
            title={"Linked Testcase"}
          />
        ) : (
          <FontAwesomeIcon
            icon={faPencil}
            className="actionBtn"
            onClick={(e) => editStepAction(e, val, props.id)}
            title={"Edit Step"}
          />
        )}
      </div>
    );
  };

  const linkTC = (val) => {
    return (
      <div>
        {val.props.val ? (
          <div>
            <FontAwesomeIcon
              icon={faLink}
              className="pass-color"
              size="lg"
              title={"Linked Testcase"}
            />
            <span className="ms-1">{val.props.val}</span>
          </div>
        ) : (
          "-"
        )}
      </div>
    );
  };

  const importTestHeader = () => {
    return (
      <div className="d-flex justify-content-start w-100">
        <div className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={(e) => {
              e?.preventDefault();
              const value = e.target.value;
              let _filters = { ...importManualFilters };
              _filters["global"].value = value;
              setImportManualFilters(_filters);
              setGlobalFilterValue(value);
            }}
            placeholder="Search"
            className="reqFormInputs"
          />
        </div>
      </div>
    );
  };
  const getTable = () => {
    return (
      <div className="p-2">
        {allTC ? (
          <DataTable
            reorderableColumns
            selection={selectedTC}
            onSelectionChange={(e) => setSelectedTC(e.value)}
            removableSort
            resizableColumns
            header={importTestHeader}
            stripedRows
            filters={importManualFilters}
            onFilter={(e) => setImportManualFilters(e.filters)}
            columnResizeMode="expand"
            value={allTC && allTC["data"] ? allTC["data"] : null}
            paginator
            rows={25}
            filterDisplay="menu"
            responsiveLayout="scroll"
            emptyMessage={<NoData />}
            currentPageReportTemplate="Total {totalRecords} Records Found"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown "
            rowsPerPageOptions={[5, 10, 25, 50, 80, 100]}
            scrollHeight={"45vh"}
            className="test-stepTable"
          >
            <Column
              selectionMode="single"
              headerClassName={`headercolumnsPrime`}
              className={"columnsPrime"}
              headerStyle={{ width: "3em" }}
            ></Column>

            {allTC && allTC["data"] ? (
              createColumns(allTC["headers"], true, false, allTC["data"])
            ) : (
              <></>
            )}
          </DataTable>
        ) : (
          tableSkeleton(false)
        )}
      </div>
    );
  };
  // const textEditor = (options) => {
  //     return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
  // };

  const saveDraft = (e) => {
    setSavingDialog(true);
    httpService
      .saveFinalDraft(props.id)
      .then((data) => {
        getToastMessage(data);
        if (
          data["operation"] &&
          data["operation"].toLowerCase() === "success"
        ) {
          setSavingDialog(false);
          props.setShowDraftTab(false);
        }
      })
      .catch((err) => {
        handleToastErrorCatch(err);
      });
  };

  const getTCtoLink = (e) => {
    setTcDialog(true);
    httpService
      .getManualTCToLink(props.id)
      .then(async (data) => {
        setAllTC(data["data"]);
        getErrorMessage(data);
      })
      .catch((err) => {
        toast.error(
          err["response"]
            ? err["response"]["data"]["message"]
            : `Something Went Wrong. Please refresh the Page / Try Again`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "mt-5",
          }
        );
      });
  };

  return (
    <div>
      <div className="mt-3">
        {testStepsData ? (
          <DataTable
            reorderableColumns
            header={
              <>
                <div className="d-flex align-items-center">
                  <>
                    <div className="">Test Step</div>
                    <div className="d-flex ms-auto">
                      <button
                        className="btn-success btn themeBtnOutline p-2"
                        onClick={(e) => setNewStepDialog(true)}
                      >
                        <span>
                          <FontAwesomeIcon icon={faPlus} className="me-2" />
                        </span>
                        Add Step(s)
                      </button>
                      <button
                        className="btn-success btn themeBtnOutline ms-2 p-2"
                        onClick={(e) => getTCtoLink(e)}
                      >
                        <span>
                          <FontAwesomeIcon icon={faLink} className="me-2" />
                        </span>
                        Import/Copy Testcase As Step
                      </button>
                    </div>
                  </>
                </div>
              </>
            }
            onFilter={(e) => {
              setDraftFilters(e.filters);
            }}
            editMode="row"
            onRowEditComplete={onRowEditComplete}
            resizableColumns
            stripedRows
            columnResizeMode="expand"
            value={testStepsData ? testStepsData["data"] : null}
            paginator
            rows={25}
            dataKey="id"
            filters={draftFilters}
            rowsPerPageOptions={[5, 10, 25, 50, 80, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            paginatorRight={
              <button
                className="btn-success btn themeBtn p-2"
                onClick={(e) => saveDraft(e)}
                disabled={savingDialog || testStepsData?.data?.length === 0}
              >
                Save Draft
              </button>
            }
            paginatorLeft={<></>}
            scrollHeight={"50vh"}
            filterDisplay="menu"
            responsiveLayout="scroll"
            emptyMessage={<NoData />}
            currentPageReportTemplate="Total {totalRecords} Record(s) Found"
            removableSort
            className="test-stepTable"
          >
            {createColumns(
              testStepsData["headers"],
              true,
              true,
              testStepsData["data"],
              false,
              null,
              { customAction: removeStepFromTC },
              {
                linkTC: true,
                stepScreenshot: true,
                editStep: true,
              },
              {
                linkTC: linkTC,
                stepScreenshot: stepScreenshot,
                editStep: editStep,
              },
              {
                linkTC_props: {
                  id: mtc_id.current,
                  getStepsFn: getSteps,
                },
              }
              // null,
              // true,
              // textEditor,
            )}
            {/* <Column className='columnsPrime' rowEditor header="Edit Step" headerStyle={{ width: '8%', minWidth: '6rem' }}></Column> */}
          </DataTable>
        ) : (
          tableSkeleton(true)
        )}
      </div>
      <Dialog
        blockScroll={true}
        draggable={false}
        header="Create New Step"
        visible={newStepDialog}
        onHide={() => {
          setNewStepDialog(false);
          setDescription("");
          setStepName("");
          setScreenshotRequired("No");
          setExpectedResult("");
        }}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "50vw" }}
      >
        {stepForm("add")}
      </Dialog>
      <Dialog
        blockScroll={true}
        draggable={false}
        header="Edit Step"
        visible={editStepDialog}
        onHide={() => {
          setEditStepDialog(false);
          setDescription("");
          setStepName("");
          setScreenshotRequired("No");
          setExpectedResult("");
          toEditStepId.current = null;
        }}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "50vw" }}
      >
        {stepForm("edit")}
      </Dialog>
      <Dialog
        blockScroll={true}
        draggable={false}
        header="Link Testcase as Step"
        visible={tcDialog}
        onHide={() => {
          setTcDialog(false);
          setSelectedTC(null);
          setGlobalFilterValue("");
          setImportManualFilters({});
          setAllTC(null);
        }}
        footer={
          <div className="d-flex">
            <Button
              label="Import Testcase"
              type="submit"
              className="btn-success btn themeBtn ms-auto py-2"
              disabled={
                isCopyTestcaseButtonLoading ? true : selectedTC ? false : true
              }
              onClick={(e) => linkStepToTC(e)}
              loadingIcon={"pi pi-spin pi-spinner me-2"}
              loading={isImportTestcaseButtonLoading}
              iconPos="right"
            />
            <Button
              label="Copy Testcase"
              type="submit"
              className="btn-success btn themeBtn ms-2 py-2"
              disabled={
                isImportTestcaseButtonLoading ? true : selectedTC ? false : true
              }
              onClick={(e) => copyTCAsSteps(e)}
              loadingIcon={"pi pi-spin pi-spinner me-2"}
              loading={isCopyTestcaseButtonLoading}
              iconPos="right"
            />
          </div>
        }
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "60vw" }}
        className="dialogStepDraft"
      >
        {getTable()}
      </Dialog>
      <Dialog
        blockScroll={true}
        draggable={false}
        header="Delete Test Step"
        visible={deleteDialog}
        onHide={() => {
          setDeleteDialog(false);
          setDeleteLoading(false);
          toDeleteStepname.current = null;
        }}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "50vw" }}
      >
        {deleteLoading ? (
          <div className="py-5">
            {loadingData("Deleting" + toDeleteStepname.current)}
          </div>
        ) : (
          <>
            <div className="text-center mt-3 py-2 mx-3">
              <h5>
                Are you sure you want to delete {toDeleteStepname.current} ?
              </h5>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <div
                onClick={(e) => {
                  deleteStep(e);
                }}
                className="btn btn-success"
              >
                <FontAwesomeIcon className="ms-1 tabIcon me-1" icon={faCheck} />
                Yes
              </div>
              <div
                onClick={() => {
                  setDeleteDialog(false);
                }}
                className="btn btn-danger mx-2"
              >
                <FontAwesomeIcon className="ms-1 tabIcon me-1" icon={faXmark} />
                No
              </div>
            </div>
          </>
        )}
      </Dialog>
      <Dialog
        blockScroll={true}
        draggable={false}
        visible={savingDialog}
        onHide={() => {
          setSavingDialog(false);
        }}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "30vw" }}
      >
        <div className="py-2">{loadingData("Saving Draft...Please wait.")}</div>
      </Dialog>
    </div>
  );
};

export default TestStepsDraft;
