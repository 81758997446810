import Auth from "../../Pages/AuthPage/Auth";
import { Link, useNavigate } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import "./Signup.css";
import { connect } from "react-redux";
import { Tooltip } from "primereact/tooltip";
import userIcon from "../../../assets/userIcon.svg";
import emailIcon from "../../../assets/emailIcon.svg";
import passwordIcon from "../../../assets/passwordIcon.svg";
import firstname from "../../../assets/First name.svg";
import lastname from "../../../assets/Last name.svg";
import { toast } from "react-toastify";
import { Password } from "primereact/password";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { register } from "../../../actions/auth";
import httpService from "../../../services/http.service";
import { Dropdown } from "primereact/dropdown";
import { logPageTitleForGA } from "../../Helper/Helper";
import { statementError } from "../../Helper/ToastHelper";
import md5Hex from "md5-hex";

const Signup = (props) => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [validUsername, setValidUsername] = useState(false);
  const [showvalid, setShowValid] = useState(false);
  const [showInputBox, setShowInputBox] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [validUsernameMessage, setValidUsernameMessage] = useState(null);
  const [focused, setFocused] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [apiCallsInProgress, setApiCallsInProgress] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    strengthCheck(e.target.value);
    // strengthCheck(password);
  };

  const strengthCheck = (password) => {
    const mediumRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
    );
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
    );

    if (strongRegex.test(password)) {
      setFeedback("Strong");
    } else if (mediumRegex.test(password)) {
      setFeedback("Medium");
    } else {
      setFeedback(null);
    }
  };

  // const isPasswordValid = strengthCheck(password);
  const getPasswordTemplate = (props) => {
    // const promptLabel = getPromptLabel();
    return (
      <div>
        {feedback && <span className={`password-strength ${feedback}`}></span>}{" "}
      </div>
    );
  };

  useEffect(() => {
    let title = "Jewel | Signup";
    document.title = title;
    logPageTitleForGA(title);
  }, []);
  useEffect(() => {
    const pattern = /[#%&\\]/;
    if (username.length > 0) {
      if (pattern.test(username)) {
        setValidUsername(true);
        setValidUsernameMessage("Invalid username entered");
        setShowValid(true);
        return;
      }
      var timeout = setTimeout(() => {
        httpService.validateUserName(username).then((data) => {
          setShowValid(true);
          if (data["isPresent"]) {
            setValidUsername(true);
            setValidUsernameMessage(data?.["Validity"]);
          } else {
            setValidUsername(false);
            setValidUsernameMessage(data?.["Validity"]);
          }
        });
        // .catch((err) => {
        //   setValidUsername(true);
        //   setValidUsernameMessage("Invalid username entered");
        // })
      }, 500);
    } else {
      setShowValid(false);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [username]);

  const usernameHeader = (
    <>
      <span>
        <Tooltip
          target=".custom-target-icon-username"
          className="signupTooltip"
        >
          <>
            <p className="">Valid Username Suggestions</p>
            <p className="mt-1">
              Mandatory
              <ul className="mt-0" style={{ lineHeight: "1.5" }}>
                <li>Alphabetical characters </li>
              </ul>
            </p>
            <p className="mt-1">
              Optional
              <ul className="mt-0" style={{ lineHeight: "1.5" }}>
                <li>Any Numerical value</li>
                <li>Special characters (dot,hyphen,underscore)</li>
              </ul>
            </p>
          </>
        </Tooltip>
        <FontAwesomeIcon
          icon={faCircleInfo}
          className="infoicon fa-sm ms-2 custom-target-icon-username"
          // data-pr-tooltip={footer}
          data-pr-position="right"
          data-pr-at="right+2 top"
        />
      </span>
    </>
  );
  const header = (
    <>
      {/* <h6> */}
      {/* Pick a password */}
      <span>
        <Tooltip target=".custom-target-icon" className="signupTooltip">
          <>
            <p className="">Suggestions</p>
            <ul className="mt-0" style={{ lineHeight: "1.5" }}>
              <li>At least one lowercase</li>
              <li>At least one uppercase</li>
              <li>At least one numeric</li>
              <li>At least one special character</li>
              <li>Minimum 8 characters</li>
            </ul>
          </>
        </Tooltip>
        <FontAwesomeIcon
          icon={faCircleInfo}
          className="infoicon fa-sm ms-2 custom-target-icon"
          // data-pr-tooltip={footer}
          data-pr-position="right"
          data-pr-at="right+2 top"
        />
      </span>
      {/* </h6> */}
    </>
  );

  const validateFields = () => {
    if (
      firstName?.length === 0 ||
      lastName?.length === 0 ||
      username.length === 0 ||
      email.length === 0 ||
      password.length === 0 ||
      confirmPassword.length === 0
    ) {
      toast.error("All fields are required !", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (!/^[A-Za-z]+$/i.test(firstName?.trim())) {
      toast.error("Invalid firstname", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (!/^[A-Za-z]+$/i.test(lastName?.trim())) {
      toast.error("Invalid lastname", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (!/^(?=.*[a-zA-Z])[a-zA-Z0-9_.-]*$/i.test(username)) {
      toast.error("Invalid username", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      toast.error("Invalid Email !", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (password !== confirmPassword) {
      toast.error("Password and Confirm Password should be perfect match !", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (feedback === null) {
      toast.error("Password too weak ! ", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      return true;
    }
  }

  const registerUser = (e) => {
    e.preventDefault();
    if (validateFields()) {
      setIsLoading(true);
      setApiCallsInProgress(true);
      props
        .dispatch(
          register(
            firstName?.trim(),
            lastName?.trim(),
            username,
            email,
            md5Hex(password),
          )
        )
        .then((data) => {
          setIsLoading(false);
          setApiCallsInProgress(false);
          toast.success("User Registered.", {
            position: "top-left",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          navigate("/account-verification", {
            state: {
              firstName: firstName,
              lastName: lastName,
              username: username,
              email: email,
              hashedPassword: md5Hex(password),
            },
          });
        })
        .catch((err) => {
          setIsLoading(false);
          setApiCallsInProgress(false);
        });
    }
    else {
      return;
    }

  };

  return (
    <>
      <Auth>
        {/* <div className='container'> */}
        <div className="d-flex card customSignup">
          <div className="card-body py-4 px-lg-5 px-md-5 px-sm-4 px-xs-2">
            <div className="text-center">
              <span className="titleSignup mt-5">Register New User</span>
              <br />
              <span className="acc">Create your account and get started.</span>
            </div>
            <form
              className="mt-3 formSignup"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="mt-2 inputfield">
                <img src={firstname} />
                <input
                  type="text"
                  className="form-control"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="Firstname"
                  disabled={showInputBox || showDropDown}
                />
              </div>
              <div className="mt-2 inputfield">
                <img src={lastname} />
                <input
                  type="text"
                  className="form-control"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Lastname"
                  disabled={showInputBox || showDropDown}
                />
              </div>
              <div className="mt-2 inputfield">
                <img src={userIcon} />
                <input
                  onBlur={() => {
                    setShowValid(false);
                    setFocused(false);
                  }}
                  onFocus={() => {
                    setFocused(true);
                    if (username.length > 0) {
                      setShowValid(true);
                    }
                  }}
                  type="text"
                  className={`form-control ${validUsername && username.length > 0
                    ? "notValidUsername"
                    : ""
                    }`}
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                  value={username}
                  placeholder="Username"
                  disabled={showInputBox || showDropDown}
                />
                <div className="infoUsername me-3">{usernameHeader}</div>

                {showvalid && focused ? (
                  validUsername ? (
                    <span className="notAvail ms-2">
                      <span className="me-1">{validUsernameMessage}</span>
                      <FontAwesomeIcon icon={faCircleXmark} />
                    </span>
                  ) : (
                    <span className="avail ms-2">
                      <span className="me-1">{validUsernameMessage}</span>
                      <FontAwesomeIcon icon={faCircleCheck} />
                    </span>
                  )
                ) : null}
              </div>
              <div className="mt-2 inputfield">
                <img src={emailIcon} />
                <input
                  type="email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  disabled={showInputBox || showDropDown}
                />
              </div>

              <div className="mt-2 inputfield">
                <img src={passwordIcon} />
                <Password
                  id="passField"
                  onChange={(e) => handlePasswordChange(e)}
                  placeholder="Password"
                  toggleMask
                  value={password}
                  feedback={false}
                  // regEx = {regexOptions}
                  // promptTemplate = {getPasswordTemplate}
                  header={header}
                  disabled={showInputBox || showDropDown}
                />

                {password && (
                  <div>
                    {/* <span>{feedback ? feedback : "Weak"}</span> */}
                    <div className="strength-meter">
                      <div
                        className={`strength-meter-fill strength-${feedback}`}
                      />
                    </div>
                    <p style={{ fontWeight: "bold" }}>
                      Password Strength: {feedback ? feedback : "weak"}
                      {header}
                    </p>
                  </div>
                )}
              </div>

              <div className="mt-2 inputfield">
                <img src={passwordIcon} />
                <Password
                  id="passField"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm Password"
                  toggleMask
                  value={confirmPassword}
                  // regEx = {regexOptions}
                  feedback={false}
                  disabled={showInputBox || showDropDown}
                />
              </div>

              <div className="mt-1 forgotPass">
                <span className="already mt-1 forgotPass">
                  Already Registered ?
                </span>{" "}
                <Link to="/login">Login</Link>
              </div>

              <div className="text-center">
                <button
                  type="submit"
                  disabled={isLoading || validUsername || apiCallsInProgress} // Disable the button if isLoading, validUsername, or API calls are in progress
                  className="mt-3 btn btn-primary text-center px-5"
                  onClick={(e) => registerUser(e)}
                >
                  Register
                </button>
              </div>

            </form>
          </div>
        </div>
        {/* </div> */}
      </Auth>
    </>
  );
};

function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  return {
    isLoggedIn,
    message,
  };
}

export default connect(mapStateToProps)(Signup);
